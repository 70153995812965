import Image from 'next/image';
import React from 'react';
import styles from './footer.styles';

export interface FooterProps {
    footerColor?: string;
}

const Footer: React.FC<FooterProps> = props => {
    return (
        <footer className={'w-full relative text-center text-3xs md:text-xs py-3 xl:py-4 ' + props.footerColor}>
            <style jsx>{styles}</style>

            <span className=" text-white">
                All rights reserved
                <p className="mx-1 md:mx-2 inline">|</p>
                <a href="#">Terms and Conditions</a>
                <p className="mx-1 md:mx-2 inline">|</p>
                Realm Architectural Workshop 2021
                <p className="mx-1 md:mx-2 inline">|</p>
                <a className="leading-none align-bottom relative" href="https://www.booninterior.com">
                    <div className="left-0 bottom-0 inline-block w-14">
                        <Image src="/boon-text-white.png" layout="intrinsic" width="45" height="16" />
                    </div>
                </a>
            </span>
        </footer>
    );
};

Footer.defaultProps = {
    footerColor: 'bg-realm-dark',
};

export default Footer;
