export const MENU_LINKS = [
    {
        text: 'HOME',
        link: '/',
    },
    {
        text: 'ABOUT',
        link: '/about',
    },
    {
        text: 'PROJECTS',
        link: '/projects',
    },
    {
        text: 'THE TEAM',
        link: '/the-team',
    },
    {
        text: 'CONTACT',
        link: '/contact-us',
    },
];
