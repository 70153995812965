import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import HeaderMenu, { MenuItemEntry } from './menu/menu';
import { MENU_LINKS } from '@config/menu';
import Image from 'next/image';
import { throttle } from 'lodash';
import { useBreakpoints } from '@hooks/use-breakpoints';

export type HeaderMode = 'sticky' | 'fixed' | 'fixed-sticky';
const HEADER_OFFSET = 40;

export interface HeaderProps {
    mode?: HeaderMode;
    baseHeightClass?: string;
    baseContentWidthClass?: string;
    navBaseHeightClass?: string;
    navBaseContentWidthClass?: string;
    logoLink?: string;
    isLogoAbsolute?: boolean;
    menuLinks?: MenuItemEntry[];
    menuColor?: string;
    borderColor?: string;
    hoverBorderColor?: string;
}

const Header: React.FC<HeaderProps> = props => {
    const [mode, setMode] = useState(props.mode === 'fixed-sticky' ? 'fixed' : props.mode);
    const { isLg } = useBreakpoints();

    let headerClasses;

    switch (mode) {
        case 'sticky':
            headerClasses = 'fixed bg-black shadow-lg opacity-[.4]';
            break;
        case 'fixed':
            headerClasses = 'absolute bg-transparent mt-8 lg:mt-10';
            break;
    }

    const generateLogo = (href = '/') => (
        <a href={href} className="h-full inline-flex flex-col justify-center my-4">
            <Image
                objectFit="contain"
                layout="intrinsic"
                width={isLg ? 220 : 128}
                height={isLg ? 80 : 48}
                src="/logo.png"
                alt="logo"
                className={'Logo object-contain ' + (mode === 'fixed' ? 'h-12 md:h-16 lg:h-20' : 'h-8 md:h-10 xl:h-12')}
            />
        </a>
    );

    const handleScroll = () => {
        if (props.mode === 'fixed-sticky') {
            if (window.pageYOffset > HEADER_OFFSET) {
                setMode('sticky');
            } else {
                setMode('fixed');
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', throttle(handleScroll, 100), { passive: true });

        return () => {
            window.removeEventListener('scroll', throttle(handleScroll, 100));
        };
    }, []);

    return (
        <header
            className={
                `mode-${mode} ` +
                'quas-stencil w-screen base-header-height flex justify-center top-0 z-10 ' +
                headerClasses
            }
        >
            <style jsx>{`
                :global(header.mode-sticky) {
                    transition: all 0.3s linear;
                }

                :global(header .Logo) {
                    transition: all 0.3s linear;
                }

                :global(main) {
                    padding-top: ${props.mode === 'fixed' || props.mode === 'fixed-sticky' ? '0 !important;' : ''};
                }
            `}</style>

            <nav
                className={
                    'flex flex-wrap items-center justify-between ' +
                    props.navBaseContentWidthClass +
                    ' ' +
                    props.navBaseHeightClass
                }
            >
                <div className="MenuWrapper h-full flex items-center font-kg-medium">
                    <HeaderMenu
                        backgroundClass={mode === 'fixed' ? 'bg-white-80' : 'bg-white-95'}
                        subMenuBackgroundClass={mode === 'fixed' ? 'lg:bg-white-80' : 'lg:bg-white-95'}
                        menuLinks={props.menuLinks}
                        menuColor={props.menuColor}
                        borderColor={props.borderColor}
                        hoverBorderColor={props.hoverBorderColor}
                    />
                </div>
                <h1 className="Logo flex-1 h-full text-right">
                    {props.isLogoAbsolute ? (
                        generateLogo(props.logoLink)
                    ) : (
                        <Link href={props.logoLink}>{generateLogo()}</Link>
                    )}
                </h1>
            </nav>
        </header>
    );
};

Header.defaultProps = {
    mode: 'sticky',
    logoLink: '/',
    isLogoAbsolute: false,
    baseHeightClass: 'base-header-height',
    baseContentWidthClass: 'base-content-width-wide',
    navBaseHeightClass: 'h-full',
    navBaseContentWidthClass: 'base-content-width-wide',
    menuLinks: MENU_LINKS,
    menuColor: 'text-white lg:text-tan',
    borderColor: 'border-white lg:border-tan',
    hoverBorderColor: 'hover:border-white lg:hover:border-tan',
};

export default Header;
