import { useState, useEffect } from 'react';
import tailwind from '../../tailwind.config';

const hasWindow = typeof window !== 'undefined';

const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;

    return {
        width,
        height,
    };
};

const normalizeWidth = (width: string) => {
    const parsed = width.replace('px', '');

    return parseInt(parsed, 10);
};

const windowOrientation = () => {
    if (hasWindow) {
        return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    }
};

const getInitialBreakpoints = {
    is2Xs: false,
    isXs: false,
    isSm: false,
    isMd: false,
    isLg: false,
    isXl: false,
    isXxl: false,
    width: 0,
};

const getInitialOrientation = {
    isLandscape: false,
    isPortrait: false,
};

const is2Xs = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.xs) <= width;
    // return 360 <= width;
};

const isXs = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.xs) <= width;
    // return 400 <= width;
};

const isSm = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.sm) <= width;
    // return 640 <= width;
};

const isMd = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.md) <= width;
    // return 768 <= width;
};

const isLg = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.lg) <= width;
    // return 1024 <= width;
};

const isXl = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.xl) <= width;
    // return 1280 <= width;
};

const isXxl = (width: number) => {
    return normalizeWidth(tailwind.theme.screens.xxl) <= width;
    // return 1600 <= width;
};

const isLandscape = (orientation: string) => {
    return orientation === 'landscape';
};

const isPortrait = (orientation: string) => {
    return orientation === 'portrait';
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                setWindowDimensions(getWindowDimensions());
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [hasWindow]);

    return windowDimensions;
};

export const useBreakpoints = () => {
    const { width } = useWindowDimensions();
    const [breakpoints, setBreakpoints] = useState(getInitialBreakpoints);

    useEffect(() => {
        if (hasWindow) {
            setBreakpoints({
                is2Xs: is2Xs(width),
                isXs: isXs(width),
                isSm: isSm(width),
                isMd: isMd(width),
                isLg: isLg(width),
                isXl: isXl(width),
                isXxl: isXxl(width),
                width,
            });
        }
    }, [width]);

    return breakpoints;
};

export const useWindowOrientation = () => {
    const orient = windowOrientation();
    const [orientation, setOrientation] = useState(getInitialOrientation);

    useEffect(() => {
        if (hasWindow) {
            setOrientation({
                isLandscape: isLandscape(orient),
                isPortrait: isPortrait(orient),
            });
        }
    }, [orient]);

    return orientation;
};
