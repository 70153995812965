module.exports = {
    purge: {
        content: ['./src/**/*.tsx', './config/*.ts'],
        options: {
            whitelist: ['min-w-50', 'whitespace-nowrap', 'md:inline', 'hidden', 'md:block', 'react-tag-input'],
        },
    },
    darkMode: false, // or 'media' or 'class'
    theme: {
        screens: {
          '2xs': '360px',
          xs: '400px',
          sm: '640px',
          md: '768px',
          lg: '1080px',
          xl: '1600px',
          xxl: '2560px',
        },
        extend: {
            fontFamily: {
                'kg-bold': 'Kozgopro Bold',
                'kg-medium': 'Kozgopro Medium',
                'kg-light': 'Kozuka Gothic Pro L',
                'quas-stencil': 'Quas Stencil',
            },
            fontSize: {
                '3xs': '0.500rem',
                '2xs': '0.625rem',
            },
            colors: {
                'tan': '#b4a288',
                'tan-light': 'rgba(180, 162, 136, 0.35)',
                'realm-dark': '#353736',
                'white-50': 'rgba(255, 255, 255, 0.5)',
                'dark-85': 'rgba(53, 55, 54, 0.85)',
                'transparent': 'rgba(53, 55, 54, 0)',
            },
            boxShadow: {
                'imageHover': '0px 9px 19px 1px rgba(30, 20, 40, 0.2)',
            }
        },
    },
    variants: {
        padding: ['responsive', 'first', 'last'],
        margin: ['responsive', 'first', 'last'],
        textColor: ['responsive', 'hover', 'group-hover', 'focus', 'active', 'odd', 'even'],
        backgroundColor: ['responsive', 'hover', 'group-hover', 'focus', 'active', 'odd', 'even'],
        extend: {
            minWidth: ['responsive'],
            whitespace: ['responsive'],
            filter: ['hover', 'focus'],
        }
    },
    plugins: [require('tailwindcss-typography')({})],
}
