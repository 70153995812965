import css from 'styled-jsx/css';

export default css`
    .NavMenuItem.hoverable:hover > :global(ul.NavSubMenu) {
        display: block !important;
    }

    .NavMenuItem > :global(.NoSpacesAfter) {
        margin-right: 0 !important;
    }

    .NavMenuItem > :global(.NoSpacesBefore) {
        margin-left: 0 !important;
    }

    .LoginSeparator > :global(a) {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
        cursor: default !important;
    }

    .LoginSeparator > :global(a):hover {
        color: inherit !important;
    }
`;
