import Header, { HeaderMode, HeaderProps } from '@common/header/header';
import React from 'react';
import Footer from './footer/footer';

export interface LayoutProps {
    headerMode?: HeaderMode;
    baseHeaderPaddingClass?: string;
    headerOptions?: HeaderProps;
    footerColor?: string;
}

/**
 * The main application layout
 *
 * @param ComposedComponent
 * @param overrides
 */
const Layout = (ComposedComponent, overrides: LayoutProps = {}) => props => {
    return (
        <React.Fragment>
            <Header {...overrides.headerOptions} mode={overrides.headerMode} />
            <main className={'z-0 -flex justify-center flex-col items-center w-screen relative bg'}>
                <div className="w-full">
                    <ComposedComponent {...props} />
                </div>
            </main>
            <Footer footerColor={overrides.footerColor} />
        </React.Fragment>
    );
};

export default Layout;
