import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { useBreakpoints } from '@hooks/use-breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import styles from './menu.styles';

interface MenuProps {
    backgroundClass?: string;
    subMenuBackgroundClass?: string;
    menuLinks: MenuItemEntry[];
    menuColor?: string;
    borderColor?: string;
    hoverBorderColor?: string;
}

export interface MenuItem {
    text: string;
    link: string;
    absolute?: boolean;
    customClass?: string;
    action?: string;
    view?: string;
    submenu?: MenuItem[];
}

export interface MenuActions {
    [key: string]: () => void;
}

export interface MenuItemEntry extends MenuItem {
    submenu?: MenuItem[];
}

const HeaderMenu: React.FC<MenuProps> = props => {
    const { isLg } = useBreakpoints();
    const router = useRouter();
    const path = router.pathname;

    const [showMenu, setMenuShow] = useState(false);
    const [showSubMenu, setSubMenuShow] = useState<string>(undefined);
    const [subMenuToggled, setSubMenuToggled] = useState(false);

    const onClickMenu = (event: React.MouseEvent) => {
        event.preventDefault();

        if (showMenu === true) {
            setMenuShow(false);
            setSubMenuShow(undefined);
            setSubMenuToggled(false);
            return;
        }

        setMenuShow(true);
    };

    const onMouseEnterSubMenu = (event: React.MouseEvent, item: MenuItem) => {
        if (showSubMenu === item.text && subMenuToggled) {
            setSubMenuShow('');
            setSubMenuToggled(false);
            return;
        }
        setSubMenuShow(item.text);
        setSubMenuToggled(true);
    };

    const onMenuItemClick = (event: React.MouseEvent, item: MenuItem) => {
        if (!isLg) {
            onMouseEnterSubMenu(event, item);
        }
    };

    const generateAnchor = item => (
        <a
            data-href={item.link}
            className={
                `lg:ml-6 xl:ml-10 hover:border-b ${props.hoverBorderColor} ` +
                'cursor-pointer block uppercase hover:font-bold ' +
                'w-[4.5rem] lg:w-auto lg:h-full lg:flex items-center py-1 lg:py-0 ' +
                (item.link === path ? `border-b font-bold ${props.borderColor}` : '')
            }
            onClick={event => {
                onMenuItemClick(event, item);
            }}
        >
            {item.text}
        </a>
    );

    const generateLI = item => {
        if (item.absolute) {
            return (
                <a
                    href={item.link}
                    className={
                        'cursor-pointer block uppercase hover:border-tan hover:font-bold ' +
                        'ml-6 xl:ml-10 lg:h-full lg:flex items-center py-1 lg:py-0 ' +
                        (item.customClass ? item.customClass : '')
                    }
                >
                    {item.text}
                </a>
            );
        }

        if (item.link === '#' || Array.isArray(item.submenu)) {
            return generateAnchor(item);
        }

        return (
            <Link href={item.link} prefetch={false}>
                {generateAnchor(item)}
            </Link>
        );
    };

    const generateUL = (items: MenuItem[], menuText: string, depth = 0) => (
        <ul
            className={
                'NavSubMenu pt-2 ml-4 mb-2 lg:mb-2 lg:-ml-3 lg:py-3 ' +
                props.subMenuBackgroundClass +
                ' ' +
                (depth < 1 ? 'lg:ml-0 lg:absolute lg:z-40 lg:w-auto ' : 'lg:float-right lg:-mr-4 ') +
                (showSubMenu === menuText ? ' ' : 'hidden ') +
                (showSubMenu === menuText && isLg ? 'active' : '')
            }
        >
            {items.map((item, index) => (
                <li
                    key={item.link + index}
                    className={
                        'my-2 lg:ml-3 lg:mr-8 lg:my-4 first:mt-0 last:mb-0 text-tan ' +
                        (item.customClass ? item.customClass : '')
                    }
                >
                    <React.Fragment>
                        {generateLI(item)}
                        {Array.isArray(item.submenu) ? generateUL(item.submenu, item.text, depth + 1) : ''}
                    </React.Fragment>
                </li>
            ))}
        </ul>
    );

    return (
        <React.Fragment>
            <style jsx>{styles}</style>

            <div className="block mr-4 lg:hidden">
                <button className="barIcon flex items-center text-tan" onClick={onClickMenu}>
                    <FontAwesomeIcon className="fa-lg" icon={faBars} />
                </button>
            </div>

            <div className="h-full lg:flex lg:flex-grow lg:items-center w-full lg:w-auto">
                <div
                    className={
                        'NavMenu lg:h-full base-header-top base-content-width lg:w-auto ' +
                        props.backgroundClass +
                        ' ' +
                        'absolute top-16 left-0 w-screen ' + // mobile width
                        'lg:static lg:ml-auto lg:block lg:px-0 lg:bg-transparent ' + // desktop override
                        (showMenu ? '' : 'hidden')
                    }
                    data-active-ul={showSubMenu}
                >
                    <ul
                        className={`
                            base-text-size-6 tracking-tight pl-10 py-8  bg-dark-85 lg:bg-transparent
                            lg:px-0 lg:py-0 lg:mr-3 w-40 lg:w-full lg:h-full lg:flex lg:items-center xl:pt-5 rounded-3xl ${props.menuColor}
                        `}
                    >
                        {props.menuLinks.map((menu, index) => {
                            return (
                                <li
                                    key={index + menu.link}
                                    className={
                                        'NavMenuItem my-2 first:mt-0 last:mb-0 lg:m-0 lg:h-full ' +
                                        (isLg ? 'hoverable' : ' ') +
                                        (menu.customClass ? menu.customClass : '')
                                    }
                                    onMouseEnter={event => {
                                        if (isLg) {
                                            onMouseEnterSubMenu(event, menu);
                                        }
                                    }}
                                    onMouseLeave={event => {
                                        if (isLg) {
                                            onMouseEnterSubMenu(event, menu);
                                        }
                                    }}
                                >
                                    <React.Fragment>
                                        {generateLI(menu)}
                                        {Array.isArray(menu.submenu) ? generateUL(menu.submenu, menu.text) : ''}
                                    </React.Fragment>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

HeaderMenu.defaultProps = {
    backgroundClass: 'bg-white-50',
    subMenuBackgroundClass: 'lg:bg-white-50',
    menuColor: 'text-white lg:text-tan',
    borderColor: 'border-white lg:border-tan',
    hoverBorderColor: 'hover:border-white lg:hover:border-tan',
};

export default HeaderMenu;
